<template>
  <div class="container page">
    <van-nav-bar :title="$t('gameRecord.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>


    <div class="main">
      <van-tabs v-model="active" animated swipeable  @change="OnChange" style="margin-top: 10PX">
        <van-tab v-for="(v,key) in addlist" :key="key" :title="v.name" :name="v.key" ></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('msg.下拉即可刷新')"
                        :loosing-text="$t('msg.释放即可刷新')" :loading-text="$t('msg.加载中')">
        <van-empty v-if="list.length === 0" :description="$t('msg.没有更多了')"/>
        <div v-else class="item_list" v-for="(v,keys) in list" :key="keys">
          <div class="van-cell">
            <div class="van-cell__title">
              <div class="box">
                <div style="line-height: 20PX">
                  <div style="color: rgb(41, 41, 41);" v-if="v.type == 1">{{$t("mine.recharge")}}</div>
                  <div style="color: rgb(41, 41, 41);" v-if="v.type == 2">{{$t("mine.withdraw")}}</div>
                  <div style="color: rgb(41, 41, 41);" v-if="v.type == 3">{{$t("gameRecord.place")}}</div>
                  <div style="color: rgb(41, 41, 41);" v-if="v.type == 4">{{$t("gameRecord.profit")}}</div>
                  <span class="sp1">{{v.created_at}}</span>
                  <span class="sp2" style="display: block; color: rgb(135, 135, 135); font-size: 12PX;" v-if="v.type == 1">{{$t("mine.recharge")}}</span>
                  <span class="sp2" style="display: block; color: rgb(135, 135, 135); font-size: 12PX;" v-else-if="v.type == 2 && v.money < 0">{{$t("mine.withdraw")}}</span>
                  <span class="sp2" style="display: block; color: rgb(135, 135, 135); font-size: 12PX;" v-else-if="v.type == 2 && v.money > 0">{{$t("gameRecord.reject")}}</span>
                  <span class="sp2" style="display: block; color: rgb(135, 135, 135); font-size: 12PX;" v-else >
                    {{v.game_name}}|{{v.oid}}|
                    <span v-for="(itme,key) in v.betting" :key = key>{{ key>0?",":"" }}{{ names(itme) }}</span>

                  </span>
                  <div style="color:red;">{{v.notes}}</div>
                </div>
              </div>
            </div>
            <div class="van-cell__value">
              <span style="color: rgb(60, 209, 151);line-height: 24PX;" v-if="v.money > 0">+{{ v.money }}</span>
              <span style="color: red;line-height: 24PX;" v-else>{{ v.money }}</span>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 3,
      isLoading: false,
      list: [],
      addlist: [
        {
          key: 3,
          name:this.$t('text.txt37')
        },
        {
          key: 4,
          name:this.$t('text.txt22')
        }
      ],
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    names(name) {
      if (name == "大") {
        return this.$t("lottery.big");
      } else if (name == "小") {
        return this.$t("lottery.small");
      } else if (name == "单") {
        return this.$t("lottery.single");
      } else if (name == "双") {
        return this.$t("lottery.double");
      }
      return name;
    },

    OnChange(){
      this.videolist = [];
      this.number = 0;
      this.page = 0;
      this.count = 0;
      this.getUserGameList();//获取视频列表
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('msg.刷新成功！'));
        this.isLoading = false;
      }, 500);
    },
    getUserGameList() {
      this.$http({
        method: 'get',
        url: 'user_get_game_list',
        data: {  id:this.active }
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data;
          console.log(this.list)
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    }

  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({path: '/Login'})
    } else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-empty__description{
  font-size: 16PX;
}
.van-cell {
  font-size: 16PX;
  padding:10px 20px
}
.van-cell .van-cell__title .box .left {
  width: 100PX;
  height: 100PX;
  background: linear-gradient(180deg, #fa67a3, #f32a7a);
  margin-right: 10PX;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34PX;
}
.van-cell .van-cell__title{
  width: 65%;
  flex: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #a9a7b4;
}
.van-cell .van-cell__title .box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.van-cell .van-cell__value {
  color: #f26060;
  font-size: 18PX;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  flex: auto;
  position: relative;
  overflow: hidden;
  text-align: right;
  vertical-align: middle;
  word-wrap: break-word;
}
.ballBox {
  color: #d75db4;
  font-weight: 700;
  margin-right: 8PX;
  font-size: 5vw;
}

.ressquareBox {
  border-radius: 8PX;
  padding: 0 8PX;
}

.resgardenBox {
  width: 7vw;
  border-radius: 50%;
}

.resgardenBox, .ressquareBox {
  background: linear-gradient(90deg, #f560cd, #4f2b8a);
  color: #fff;
  height: 7vw;
  line-height: 7vw;
  text-align: center;
  font-size: 3vw;
  font-weight: 700;
  margin-right: 15PX;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35PX;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35PX;
}

.container .main {
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10PX;
}

.item_list {
  padding: 7PX 7PX;
  margin: 15PX 5PX;
  background: #fff;
  border-radius: 5PX;
  line-height: 30PX;
}

.item_list .topInfo span {
  flex: 1;
  font-size: 35PX;
  font-weight: 700;
  color: #ff253f;
}

.item_list .time span {
  flex: 1;
  font-size: 25PX;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child {
  float: right;
}

.item_list .desc span {
  font-size: 25PX;
  font-weight: 700;
  color: #9b9b9b;
}

.item_list .cover {
  width: 60PX;
  height: 60PX;
  -o-object-fit: cover;
  object-fit: cover;
}

.item_list .period-number {
  margin-left: 50PX;
  margin-right: 10PX;
  height: 50PX;
  line-height: 60PX;
  font-size: 35PX;
  font-weight: 700;
  color: #000;
}

.item_list .lottery_info {
  display: flex;
}

.recent {
  display: flex;
  align-items: center;
  height: 100PX;
}

.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img {
  width: 70PX;
  height: 70PX;
  margin-right: 30PX;
}

.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 35PX;
}
</style>
