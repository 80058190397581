<template>
  <van-tabbar v-if="show" v-model="active" style="z-index:10" active-color="#7e5678" :border="true" inactive-color="#979799">
    <div class="van-tabbar" style="width: 100%;max-width: 500PX;margin: auto">

      <van-tabbar-item v-for="(k, key) in item" replace :to="k.router" :key="key">
        <span>{{ $t(k.title) }}</span>
        <template #icon="props">
          <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" v-show="key !== 2" />
          <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" class="tui" style="height: 3rem;margin-top: -20px;" v-show="key === 2" />
        </template>
      </van-tabbar-item>
    </div>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0,
      item: [
        {
          router: '/Home',
          title: "footer.home",
          icon: {
            active: '/img/footer/home_active.png',
            noactive: '/img/footer/home.png'
          }
        },

        {
          router: '/Video',
          title: "footer.video",
          icon: {
            active: '/img/footer/theater_active.png',
            noactive: '/img/footer/theater.png'
          }
        },
        {
          router: '/Choose',
          title: "text.choose",
          icon: {
            active: '/img/footer/resource.png',
            noactive: '/img/footer/resource.png'
          }
        },
        {
          router: '/Game',
          title: "text.txt15",
          icon: {
            active: '/img/footer/welfare_active.png',
            noactive: '/img/footer/welfare.png'
          }
        },
        {
          router: '/Mine',
          title: "footer.mine",
          icon: {
            active: '/img/footer/mine_active.png',
            noactive: '/img/footer/mine.png'
          }
        }
      ]
    };
  },
  methods: {

  },
  watch: {
    $route(to) {
      if (to.name == 'home') {
        this.active = 0;
        this.show = true;
      } else if (to.name == 'game') {
        this.active = 3;
        this.show = true;
      } else if (to.name == 'choose') {
        this.active = 2;
        this.show = true;
      } else if (to.name == 'video') {
        this.active = 1;
        this.show = true;
      } else if (to.name == 'mine') {
        this.active = 4;
        this.show = true;
      } else {
        this.show = false;
      }
    }
  },
  created() {

    if (this.$route.name == 'home') {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == 'game') {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == 'choose') {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == 'video') {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == 'mine') {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  }
};
</script>


